/* eslint-disable arrow-body-style  */
import React, { Component } from 'react';
import { Link, matchPath, withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { Container, Menu, Grid } from 'semantic-ui-react';
// import { SocialLinks } from '../shared';
import NSImage from '../../modules/shared/NSImage';

@inject('agreementsStore', 'uiStore')
@withRouter
class Footer extends Component {
  componentDidMount() {
    setTimeout(() => {
      if (this.props.location.hash === '#site-footer') {
        document.querySelector(`${this.props.location.hash}`).scrollIntoView({
          block: 'start',
        });
      }
    }, 500);
  }

  render() {
    const { path, uiStore } = this.props;
    const { isMobile, isTablet } = uiStore.responsiveVars;
    const OfferFooter = ['/offerings/:id/:section?'];
    const isCampaign = matchPath(path, { path: OfferFooter }) !== null;
    const isNewCampaign = this.props.location.pathname.startsWith('/offerings');
    return (
      <footer id="site-footer" className={`${isCampaign ? 'offering-footer' : ''} ${isNewCampaign && isTablet ? 'offering-footer-v2' : ''}`}>
        <Container>
          <Grid stackable>
            <Grid.Column computer={6} tablet={16} mobile={16} className="footer-left">
              <div className="footer-left-nav">
                <Menu
                  text
                  vertical
                  className={isTablet ? 'center-align m-auto' : ''}
                >
                  <Menu.Item header>Legal</Menu.Item>
                  <Menu.Item as={Link} to="/legal/terms-of-use">Terms of Use</Menu.Item>
                  <Menu.Item as={Link} to="/legal/privacy-policy">Privacy Policy</Menu.Item>
                  <Menu.Item as={Link} to="/legal/form-crs">Form CRS</Menu.Item>
                  <Menu.Item as={Link} to="/legal/legal-documents">Legal Documents</Menu.Item>
                </Menu>
                <>
                  <div className={isTablet ? 'mt-20 center-align' : ''}>
                    <a href="https://www.aoiplatforms.org/" target="_blank" rel="noopener noreferrer">
                      <NSImage path="aoip.png" />
                    </a>
                  </div>
                </>
                <>
                  <div className={`grey-header mt-30 ${isMobile ? 'mt-20 center-align' : ''}`}>
                    <b>Have a question?</b>
                    <p className="copyright-info">Contact us at <a className="social-links" href="mailto:investors@republic.co">investors@republic.co</a></p>
                  </div>
                </>
                {isMobile && (
                  <>
                    <div className="mt-30 footer-social grey-header">
                      {/* <SocialLinks size="big" /><br /><br /> */}
                      <span className={`${isMobile ? 'mt-20' : ''}`}>© 2021 Republic Investment Services LLC (f/k/a NextSeed Services LLC)</span>
                    </div>
                  </>
                )}
              </div>
            </Grid.Column>
            <Grid.Column computer={10} tablet={16} mobile={16} className="copyright-info grey-header">
              <p>
                This site is operated by Republic Investment Services LLC (f/k/a NextSeed Services LLC), which is neither a registered
                broker-dealer nor funding portal, and is beneficially wholly owned by OpenDeal Inc. dba Republic ({'"'}Republic{'"'}).
              </p>
              <p>
                Offerings hosted by ({'"'}Republic{'"'}) can be accessed at <a href="https://republic.co/" target="_blank" rel="noopener noreferrer">https://republic.co/</a>,
                a platform owned by Republic ({'"'}Republic Platform{'"'}). Securities offered on the Republic Platform are facilitated by the listed intermediaries owned and
                operated by Republic and not in association with or under common control with NextSeed Securities, LLC ({'"'}NextSeed Securities{'"'}). Securities on the
                Republic Platform are subject to applicable terms and conditions. No offering on the Republic Platform has been recommended or approved by any federal or
                state securities commission or regulatory authority and is not endorsed or recommended by NextSeed Securities LLC.
              </p>
              <p>
                NextSeed Securities, LLC is a registered broker dealer and member of <a href="https://www.finra.org/" target="_blank" rel="noopener noreferrer">FINRA</a>{' '}
                (check out the background of our broker-dealer on <a href="https://brokercheck.finra.org/firm/summary/291387" target="_blank" rel="noopener noreferrer">BrokerCheck®</a>),
                which is independently owned and managed and is not in association with or under common control with Republic or any regulated intermediaries owned or controlled by Republic.
                NextSeed Securities does not provide any investment advice or recommendation and does not provide any legal or tax advice with respect to any securities, and is NOT
                associated with or involved in any investment offerings available on the Republic Platform.
              </p>
              <p>
                All securities listed on the Republic Platform are being offered by, and all information included on this site is the responsibility of, the applicable issuer of such
                securities. In making an investment decision, investors must rely on their own examination of the issuer and the terms of the offering, including the merits and risks
                involved. Any investments are speculative, illiquid and involve a high degree of risk, including the possible loss of your entire investment.
              </p>
              <p>
                By accessing this site and any pages thereof, you agree to be bound by the <Link to="/agreements/legal/terms-of-use" target="_blank">Terms of Use</Link> and
                {' '}<Link to="/agreements/legal/privacy-policy" target="_blank">Privacy Policy</Link>.
              </p>
              {!isMobile && (
                <div className="mt-20 footer-social grey-header">
                  {/* <SocialLinks /> */}
                  <span className={`${isMobile && 'mt-20'}`}>© 2021 Republic Investment Services LLC (f/k/a NextSeed Services LLC)</span>
                </div>
              )}
            </Grid.Column>
          </Grid>
        </Container>
      </footer>
    );
  }
}

export default Footer;
