import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Header, Item, Container, Responsive, Button } from 'semantic-ui-react';
import NSImage from '../../../shared/NSImage';

const HeaderItem = ({ communityHeader, responsiveVars }) => (
  <section className={responsiveVars.uptoTablet ? 'pt-50 pb-50 ' : 'pt-100 pb-70'}>
    <Header as="h1" className={`${responsiveVars.isMobile ? 'mb-40 pl-20 pr-20' : 'mb-40 center-align'} custom-h1-header build-community-header`}>
      Build your community,<Responsive as="br" />
      diversify your portfolio
    </Header>
    <p className={`${responsiveVars.uptoTablet ? 'mb-20 pl-20 pr-20' : 'mb-70 center-align'} r-public`}>
      Build the future world you want to see by investing directly <Responsive as="br" minWidth={768} />
      in businesses that are the backbone of local communities.
    </p>
    {
      <Container className="center-align">
        <Item.Group className="horizontal-items home-page">
        {communityHeader.map(b => (
          <Item className="community-items">
            <Item.Content className="center-align">
              <NSImage className="mb-20" centered path={b.icon} />
              <Item.Header as="h6">{b.title}</Item.Header>
              <Item.Meta>{b.description}</Item.Meta>
            </Item.Content>
          </Item>
        ))
        }
        </Item.Group>
        <Button
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          primary
          href="https://republic.co/local-investing?utm_source=nextseed.com&amp;utm_medium=website&amp;utm_campaign=republic_local"
        >
          Get started
        </Button>
        <p className={`${responsiveVars.isMobile ? '' : 'center-align'} mt-40 note`}>
          <sup>*</sup>Payments are not guaranteed
        </p>
      </Container>
    }
  </section>
);

@inject('navStore', 'userDetailsStore', 'authStore', 'userStore', 'uiStore')
@withRouter
@observer
class BuildCommunityHeader extends Component {
  render() {
    const { responsiveVars } = this.props.uiStore;
    const headerList = [
      {
        id: 1,
        title: <>Regular cash payments<sup>*</sup></>,
        description: 'Debt investments can offer regular cash payments',
        icon: 'icons/community_cash.png',
      }, {
        id: 2,
        title: 'Expand your portfolio ',
        description: 'Diversify your portfolio with Main Street businesses',
        icon: 'icons/community_expand.png',
      }, {
        id: 3,
        title: 'Invest in your own backyard',
        description: 'Invest in local community-building businesses',
        icon: 'icons/community_backyard.png',
      },
    ];
    return (
      <HeaderItem communityHeader={headerList} responsiveVars={responsiveVars} />
    );
  }
}

export default BuildCommunityHeader;
