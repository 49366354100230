import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Banner from '../components/Banner';
import BuildCommunityHeader from '../components/BuildCommunityHeader';
import NextseedAccountBanner from '../components/NextseedAccountBanner';
import RaiseRepublicBanner from '../components/RaiseRepublicBanner';

@inject('uiStore', 'publicStore')
@observer
class Home extends Component {
  render() {
    const { setShowButton, getRedirectUrl } = this.props.publicStore;
    setShowButton();
    getRedirectUrl();
    return (
      <>
        <Banner />
        <BuildCommunityHeader />
        <NextseedAccountBanner />
        <RaiseRepublicBanner />
      </>
    );
  }
}

export default Home;
