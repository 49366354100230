import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Header, Grid, Button, Responsive, Container } from 'semantic-ui-react';
// import NSImage from '../../../shared/NSImage';
import { Logo } from '../../../../theme/shared';

@inject('uiStore')
@withRouter
@observer
class RaiseRepublicBanner extends Component {
  render() {
    const { responsiveVars } = this.props.uiStore;
    const { isMobile } = responsiveVars;
    return (
      <section className={`${isMobile ? 'pt-30 pb-50' : 'pt-100 pb-100'}`}>
          <Container className={isMobile ? 'mt-20' : ''}>
            <Grid columns={2} stackable className="space-between">
              <Grid.Column width={11}>
                {isMobile && <Logo dataSrc="RaiseCapitalRepublic" size="small" restOfClasses="centered" />}
                <Header as="h1" className={`${isMobile ? 'mb-20' : 'mb-30'} build-community-header`}>Looking to raise capital <Responsive minWidth={768} as="br" />for your growing business?</Header>
                <p className={`${isMobile ? '' : 'mb-30'} r-public`}>
                  Whether expanding or opening a brand-new concept,<Responsive minWidth={992} as="br" />
                  we make it easy to raise money from thousands of local investors.
                </p>
                {!isMobile
                  && <Button as="a" primary target="_blank" rel="noopener noreferrer" href="https://republic.co/apply?utm_source=nextseed.com&amp;utm_medium=website&amp;utm_campaign=republic_local" className="r-banner">Apply Online</Button>
                }
              </Grid.Column>
              <Grid.Column width={5}>
                {!isMobile && <Logo dataSrc="RaiseCapitalRepublic" size="Large" restOfClasses="pt-30 ml-45" />}
                {isMobile
                && <Button as={Link} to="/business-application" primary fluid className="mb-40 mt-10 relaxed">Apply Online</Button>}
              </Grid.Column>
            </Grid>
          </Container>
        </section>
    );
  }
}

export default RaiseRepublicBanner;
