import { startCase } from 'lodash';
import { PRIVATE_NAV } from './NavigationMeta';

const ADMIN_MODULES = PRIVATE_NAV.filter(n => n.accessibleTo && n.accessibleTo.includes('admin'));
const ADMIN_TITLE_META = {};
ADMIN_MODULES.forEach((n) => {
  ADMIN_TITLE_META[`/dashboard/${n.to}`] = `${n.title} - Republic Investment Services LLC`;
});

const INVESTOR_ACC_META = {};
const INVESTOR_INVESTMENT_ACC_META = {};

['individual', 'ira', 'entity'].forEach((acc) => {
  ['portfolio', 'transfer-funds', 'bank-accounts', 'transactions', 'statements'].forEach((subtab) => {
    INVESTOR_ACC_META[`/dashboard/account-details/${acc}/${subtab}`] = `${acc === 'ira' ? 'IRA' : startCase(acc)} Account | ${startCase(subtab)} - Republic Investment Services LLC`;
  });
  INVESTOR_INVESTMENT_ACC_META[`/dashboard/setup/account-creation/${acc}`] = `Create ${acc === 'ira' ? 'IRA' : startCase(acc)} Investment Account - Republic Investment Services LLC`;
});

export const TITLES = {
  '/offerings/': 'Investment Opportunities - Republic Investment Services LLC',
  '/communities/': 'Communities & Partners - Republic Investment Services LLC',
  '/business-referral': 'Refer a Business - Republic Investment Services LLC',
  '/business': 'How It Works | Businesses - Republic Investment Services LLC',
  '/investors': 'How It Works | Investors - Republic Investment Services LLC',
  '/mission': 'Our Mission - Republic Investment Services LLC',
  '/team': 'Our Team - Republic Investment Services LLC',
  '/group': 'The Group - Republic Investment Services LLC',
  '/insights': 'Insights and Articles - Republic Investment Services LLC',
  '/education-center': 'Education Center - Republic Investment Services LLC',
  '/education-center/investor': 'Investor Education Center - Republic Investment Services LLC',
  '/education-center/business': 'Business Education Center - Republic Investment Services LLC',
  '/legal/terms-of-use': 'Terms of Use - Republic Investment Services LLC',
  '/legal/privacy-policy': 'Privacy Policy - Republic Investment Services LLC',
  '/legal/general-disclosures': 'General Disclosures - Republic Investment Services LLC',
  '/legal/general-risk-factors': 'General Risk Factors - Republic Investment Services LLC',
  '/legal/legal-documents': 'Legal Documents - Republic Investment Services LLC',
  '/login': 'Log in to Republic Investment Services LLC',
  '/register': 'Sign Up For Free - Republic Investment Services LLC',
  '/forgot-password': 'Reset Your Password - Republic Investment Services LLC',
  '/register-investor': 'Sign Up For Free - Republic Investment Services LLC',
  '/business-application': 'Raise Capital for a Business or a Real Estate Project - Republic Investment Services LLC',
  '/business-application/business': 'Apply - Republic Investment Services LLC',
  '/dashboard/application/select-application-type': 'Apply - Republic Investment Services LLC',
  '/dashboard/business-application/commercial-real-estate': 'Apply - Republic Investment Services LLC',
  '/dashboard/business-application/business': 'Pre-Qualification Application Process - Republic Investment Services LLC',
  '/business-application/commercial-real-estate': 'Apply | CRE - Republic Investment Services LLC',
  '/business-application/questions/need-help': 'Help Center - Republic Investment Services LLC',
  '/dashboard/account-settings/profile-data': 'Settings | Investor Profile - Republic Investment Services LLC',
  '/dashboard/account-settings/investment-limits': 'Settings | Investment Limits - Republic Investment Services LLC',
  '/dashboard/account-settings/security': 'Settings | Security - Republic Investment Services LLC',
  '/dashboard/account-settings/agreements': 'Settings | Agreements - Republic Investment Services LLC',
  '/dashboard/welcome-packet': 'Education Center | Welcome Packet - Republic Investment Services LLC',
  '/dashboard/knowledge-base': 'Education Center | Knowledge Base - Republic Investment Services LLC',
  '/dashboard/faq': 'Education Center | FAQ - Republic Investment Services LLC',
  ...INVESTOR_ACC_META,
  ...ADMIN_TITLE_META,
  ...INVESTOR_INVESTMENT_ACC_META,
  '/dashboard/setup/account-creation': 'Create Investment Account - Republic Investment Services LLC',
  '/dashboard/setup': 'Investor Dashboard - Republic Investment Services LLC',
  '/dashboard/referrals': 'Referrals - Republic Investment Services LLC',
  '/': 'Invest in Small Business | Republic Investment Services LLC',
};
