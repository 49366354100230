import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Header, Container, Button } from 'semantic-ui-react';
// import NSImage from '../../../shared/NSImage';
import { Logo } from '../../../../theme/shared';

@inject('uiStore')
@withRouter
@observer
class NextseedAccountBanner extends Component {
  render() {
    const { responsiveVars } = this.props.uiStore;
    return (
      <section className="bg-ns">
        <Container className={`${responsiveVars.isMobile ? 'mb-10 mt-0' : ''} center-align`}>
          <Logo restOfClasses="centered" dataSrc="LogoWhite" size="small" />
          <Header as="h5" className={responsiveVars.isMobile ? 'mt-10 mb-10' : 'mb-20'}>Looking for your NextSeed account?</Header>
          <p>
            You&apos;ve come to the right place. Log In to view your past investments.
          </p>
          <Button basic inverted as={Link} to="login">Log In</Button>
        </Container>
      </section>
    );
  }
}

export default NextseedAccountBanner;
