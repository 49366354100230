import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Header, Container, Dimmer, Loader, Grid, Button } from 'semantic-ui-react';
// import NSImage from '../../../shared/NSImage';

@inject('uiStore', 'publicStore')
@withRouter
@observer
class Banner extends Component {
  render() {
    const { responsiveVars } = this.props.uiStore;
    // const { uptoTablet } = responsiveVars;
    return (
      <section className="banner business-banner">
        <Container className={responsiveVars.isMobile ? 'mobile-banner' : ''}>
          <Grid>
            <Grid.Column widescreen={8} computer={8} tablet={16} mobile={16} stackable>
              <div className="banner-caption">
                <Header className="home-header custom-h1-header" as="h1">
                  Invest in <span style={{ color: '#00FDFF' }}>local<br />businesses</span>
                </Header>
                <p className="r-public">Invest in growing Main Street <br />businesses all over the U.S.</p>
                <Button as="a" target="_blank" rel="noopener noreferrer" href="https://republic.co/local-investing?utm_source=nextseed.com&amp;utm_medium=website&amp;utm_campaign=republic_local" className="white-button r-banner">Learn more</Button>
                <p className="r-public note">Investments can result in total loss and<br /> may be impossible to resell. <a target="_blank" rel="noopener noreferrer" href="https://republic.co/learn/investors/how-it-works?utm_source=nextseed.com&amp;utm_medium=website&amp;utm_campaign=republic_local">Learn more</a></p>
              </div>
            </Grid.Column>
          </Grid>
        </Container>
        {this.props.withDimmer && (
          <Dimmer active className="fullscreen">
            <Loader active>Loading..</Loader>
          </Dimmer>
        )}
      </section>
    );
  }
}

export default Banner;
