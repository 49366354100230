import React from 'react';
import { toJS, observable, computed, action } from 'mobx';
import graphql from 'mobx-apollo';
import { pickBy, get, set, filter, orderBy, sortBy, includes, has, remove, uniqWith, isEqual, isEmpty, reduce, isArray, find, camelCase } from 'lodash';
import money from 'money-math';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Calculator } from 'amortizejs';
import { GqlClient as clientPublic } from '../../../../api/publicApi';
import { GqlClient as client } from '../../../../api/gqlApi';
import {
  allOfferings,
  campaignDetailsQuery,
  campaignDetailsAdditionalQuery,
  getOfferingById,
  getOfferingIdBySlug,
  campaignDetailsForInvestmentQuery,
  getOfferingsReferral,
  checkIfEarlyBirdExist,
  getOfferingForInvestorSignupBySlug,
  getPublicOfferingsList,
} from '../../queries/campagin';
import { STAGES } from '../../../constants/admin/offerings';
import { CAMPAIGN_KEYTERMS_SECURITIES_ENUM } from '../../../../constants/offering';
import { getBoxEmbedLink } from '../../queries/agreements';
import { userDetailsStore, watchListStore, userStore, navStore, authStore, offeringCreationStore, portfolioStore } from '../../index';
import Helper from '../../../../helper/utility';
import { DataFormatter } from '../../../../helper';

export class CampaignStore {
  @observable data = [];

  @observable completedOfferings = [];

  @observable details = {};

  @observable additionalDetails = {};

  @observable option = false;

  @observable campaignSideBarShow = true;

  @observable selectedReadMore = {};

  @observable selectedReadLess = {};

  @observable RECORDS_TO_DISPLAY = 12;

  @observable completedToDisplay = this.RECORDS_TO_DISPLAY;

  @observable activeToDisplay = this.RECORDS_TO_DISPLAY;

  @observable creationToDisplay = 6;

  @observable gallarySelectedImageIndex = null;

  @observable docsWithBoxLink = [];

  @observable campaignNavData = [];

  @observable totalPayment = 0;

  @observable principalAmt = 0;

  @observable totalPaymentChart = [];

  @observable showFireworkAnimation = false;

  @observable earlyBirdCheck = null;

  @observable isInvestBtnClicked = false;

  @observable isFetchedError = false;

  @observable docLoading = false;

  @observable hideCreationList = false;

  @observable temData = null;

  @observable offeringUUID = null;

  @observable inInvestmentFlow = false;

  @observable documentMeta = {
    closingBinder: { selectedDoc: null, accordionActive: true },
  };

  @observable isPostedNewComment = false;

  @observable postNavCount = { updates: 0, comments: 0 };

  @observable postedCommentCount = 0;

  @action
  setFieldValue = (field, val, path = false) => {
    if (path) {
      set(this[field], path, val);
    } else {
      this[field] = val;
    }
  }

  @action
  initRequest = (group, referralCode = false, field = 'data', publicPagination = false) => {
    let stageGroup = group;
    let groupKey = 'group';
    if (isArray(group)) {
      groupKey = 'stage';
      stageGroup = Object.keys(pickBy(STAGES, s => group.includes(s.publicRef)));
    }
    let variables = { filters: { [groupKey]: stageGroup } };
    if (referralCode) {
      variables.filters.referralCode = referralCode;
    }
    if (publicPagination) {
      variables = { ...variables, limit: 6 };
    }
    return new Promise((resolve, reject) => {
      this[field] = graphql({
        client: (!referralCode && userStore.currentUser && userStore.currentUser.sub) ? client : clientPublic,
        query: referralCode ? getOfferingsReferral : publicPagination ? getPublicOfferingsList : allOfferings,
        variables,
        fetchPolicy: 'no-cache',
        onFetch: (data) => {
          if (data && !this[field].loading) {
            const offering = publicPagination ? data.getOfferingListPaginated.length && data.getOfferingListPaginated[0]
            : data.getOfferingList.length && data.getOfferingList[0];
            resolve(offering);
          }
        },
        onError: (err) => {
          window.logger(err);
          reject();
        },
      });
    });
  }

  @action
  getOfferedBy = (offering) => {
    const offeringRegulation = get(offering, 'regulation');
    let offeredBy = '';
    switch (offeringRegulation) {
      case 'FP_TX':
        offeredBy = 'Offered by NextSeed TX, LLC';
        break;
      case 'FP_CF':
        offeredBy = 'Offered by NextSeed US, LLC';
        break;
      default:
        offeredBy = 'Offered by NextSeed Securities, LLC';
    }
    return offeredBy;
  }

  @action
  getCampaignDetails = (id, queryType = false, isValid = false) => new Promise((resolve, reject) => {
    const gqlClient = authStore.isUserLoggedIn ? client : clientPublic;
    watchListStore.setFieldValue('isWatching', false);
    this.details = graphql({
      client: gqlClient,
      query: queryType ? campaignDetailsForInvestmentQuery : campaignDetailsQuery,
      variables: { id, isValid },
      fetchPolicy: 'no-cache',
      onFetch: (data) => {
        if (data && data.getOfferingDetailsBySlug && !this.details.loading) {
          if (!queryType) {
            watchListStore.setFieldValue('isWatching', ['WATCHING', 'INVESTOR'].includes(get(data.getOfferingDetailsBySlug, 'watchListStatus')));
            this.getCampaignAdditionalDetails(id);
          }
          offeringCreationStore.setCurrentOfferingId(data.getOfferingDetailsBySlug.id);
          resolve(data.getOfferingDetailsBySlug);
        } else if (!this.details.loading) {
          offeringCreationStore.setCurrentOfferingId(data.getOfferingDetailsBySlug.id);
          resolve(false);
        }
      },
      onError: (err) => {
        offeringCreationStore.setCurrentOfferingId(null);
        portfolioStore.setFieldValue('investmentDetails', {});
        reject(err);
      },
    });
  });

  @action
  getCampaignAdditionalDetails = (id) => {
    const gqlClient = authStore.isUserLoggedIn ? client : clientPublic;
    this.additionalDetails = graphql({
      client: gqlClient,
      query: campaignDetailsAdditionalQuery,
      variables: { id, isValid: true },
      fetchPolicy: 'network-only',
      onFetch: (data) => {
        if (data && data.getOfferingDetailsBySlug && !this.additionalDetails.loading) {
          this.concatOfferingDetails(get(data, 'getOfferingDetailsBySlug'));
        }
      },
    });
  }

  @action
  concatOfferingDetails = (newData) => {
    if (newData && this.campaign && get(this.campaign, 'id') === get(newData, 'id')) {
      const campaignData = toJS(this.details);
      campaignData.data.getOfferingDetailsBySlug.updates = get(newData, 'updates');
      campaignData.data.getOfferingDetailsBySlug.comments = get(newData, 'comments');
      this.details = campaignData;
      this.navCountData();
    }
  }

  @action
  concatOfferingComments = (newData) => {
    if (newData && this.campaign && get(this.campaign, 'id') === get(newData, 'id')) {
      const campaignData = toJS(this.details);
      campaignData.data.getOfferingDetailsBySlug.comments = get(newData, 'comments');
      this.details = campaignData;
    }
  }

  @action
  updateCommentThread = (commentResponse, commentID) => {
    const comments = get(this.campaign, 'comments');
    if (commentID) {
      const currentComment = find(comments, o => o.id === commentID);
      const threadArray = currentComment.threadComments;
      threadArray.push(commentResponse);
    } else {
      // const campaignData = toJS(this.details);
      // campaignData.data.getOfferingDetailsBySlug.comments.unshift(commentResponse);
      // this.details = campaignData;
      comments.unshift(commentResponse);
    }
    this.setFieldValue('isPostedNewComment', true);
    // const commentCount = this.navCountData;
    this.navCountData();
  }

  @action
  getOfferingIdBySlug = id => new Promise((resolve) => {
    this.temData = graphql({
      client,
      query: getOfferingIdBySlug,
      fetchPolicy: 'no-cache',
      variables: { id },
      onFetch: (res) => {
        if (!this.temData.loading) {
          resolve(res.getOfferingDetailsBySlug.id);
        }
      },
      onError: () => {
        Helper.toast('Something went wrong, please try again later.', 'error');
      },
    });
  });

  @action
  getIssuerIdForOffering = id => new Promise((resolve, reject) => {
    this.details = graphql({
      client: clientPublic,
      query: getOfferingById,
      variables: { id },
      onFetch: (data) => {
        if (data && !this.details.loading) {
          if (data.getOfferingDetailsBySlug) {
            resolve(data.getOfferingDetailsBySlug);
          } else {
            reject();
          }
        }
      },
      fetchPolicy: 'network-only',
    });
  });

  @computed get allData() {
    return this.data;
  }

  @computed get getEarlyBirdCheck() {
    return this.earlyBirdCheck && this.earlyBirdCheck.data
      && this.earlyBirdCheck.data.checkEarlyBirdByInvestorAccountAndOfferingId;
  }

  @computed get earlyBirdLoading() {
    return this.earlyBirdCheck && this.earlyBirdCheck.loading;
  }

  @computed get OfferingList() {
    return (this.allData.data && this.allData.data.getOfferingList
      && toJS(this.allData.data.getOfferingList)) || [];
  }

  @computed get CompletedOfferingList() {
    return (this.completedOfferings.data && this.completedOfferings.data.getOfferingListPaginated
      ? toJS(this.completedOfferings.data.getOfferingListPaginated)
      : this.completedOfferings.data && this.completedOfferings.data.getOfferingList
      ? toJS(this.completedOfferings.data.getOfferingList) : []);
  }

  @computed get active() {
    const offeringList = this.orderedActiveList.slice();
    return offeringList.splice(0, this.activeToDisplay);
  }

  @computed get creationList() {
    return this.CompletedOfferingList.filter(o => Object.keys(pickBy(STAGES, s => s.publicRef === 'creation')).includes(o.stage));
  }

  @computed get creation() {
    const creationList = orderBy(this.creationList.slice(), c => get(c, 'updated.date'), ['desc']);
    return creationList.splice(0, this.creationToDisplay);
  }

  @computed get activeList() {
    // const activeListArr = this.OfferingList.filter(o => Object.keys(pickBy(STAGES, s => s.publicRef === 'active')).includes(o.stage));
    return orderBy(this.OfferingList, o => (get(o, 'keyTerms.shorthandBusinessName') ? get(o, 'keyTerms.shorthandBusinessName').toLowerCase() : get(o, 'keyTerms.shorthandBusinessName')), ['asc']);
  }

  @computed get orderedActiveList() {
    // const activeListArr = this.OfferingList.filter(o => Object.keys(pickBy(STAGES, s => s.publicRef === 'active')).includes(o.stage));
    const orderedActiveListArr = this.generateBanner(this.OfferingList, true);
    return orderedActiveListArr;
  }

  @computed get completed() {
    const offeringList = this.completedList.slice();
    return offeringList.splice(0, this.completedToDisplay);
  }

  @computed get completedList() {
    return sortBy(this.CompletedOfferingList.filter(o => Object.keys(pickBy(STAGES, s => s.publicRef === 'completed')).includes(o.stage)), ['order']);
    // return sortBy(this.CompletedOfferingList, ['order']);
  }

  @action
  loadMoreRecord = (type) => {
    const offeringsList = type === 'completedToDisplay' ? this.completedList : type === 'activeToDisplay' ? this.orderedActiveList : this.creationList;
    if (offeringsList.length > this[type]) {
      this[type] += (type === 'creationToDisplay' ? 6 : this.RECORDS_TO_DISPLAY);
    }
  }

  @action
  resetDisplayCounts = () => {
    this.completedToDisplay = this.RECORDS_TO_DISPLAY;
    this.activeToDisplay = this.RECORDS_TO_DISPLAY;
    this.creationToDisplay = 6;
  }

  @computed get campaign() {
    if (this.details.data && this.details.data.getOfferingDetailsBySlug) {
      return toJS(this.details.data.getOfferingDetailsBySlug);
    } if (this.details.data && this.details.data.getOfferingById) {
      return toJS(this.details.data.getOfferingById);
    }
    return {};
  }

  checkValidContent = (content, stage) => {
    const { isUserLoggedIn } = authStore;
    const { isAdmin, myCapabilities } = userStore;
    window.logger(myCapabilities);
    const access = myCapabilities.includes('OFFERINGS_FULL');
    const stageCondition = stage !== 'COMPLETE' || (stage === 'COMPLETE' && (get(content, 'contentType') !== 'DATA_ROOM'));
    return (stageCondition && ((get(content, 'scope') === 'PUBLIC') || (get(content, 'scope') === 'HIDDEN' && isUserLoggedIn && isAdmin && access)));
  }

  @computed get campaignCommentsMeta() {
    const commentMeta = {
      isValid: false,
      content: null,
      action: null,
    };

    const { campaign, campaignStatus } = this;
    const { isInvestorAccreditated, signupStatus } = userDetailsStore;
    const { activeAccounts, frozenAccounts } = signupStatus;
    const { stepInRoute } = navStore;

    const loggedInAsInvestor = authStore.isUserLoggedIn && userStore.currentUser.roles.includes('investor');
    const passedProcessingDate = get(campaignStatus, 'diffForProcessing.value') <= 0;
    const validCampaignStage = ['CREATION', 'LIVE', 'LOCK', 'PROCESSING'].includes(get(campaign, 'stage'));
    const offeringRegulation = get(campaign, 'keyTerms.regulation');
    const isOfferingRegD = ['BD_506C', 'BD_506B'].includes(offeringRegulation);

    if (!loggedInAsInvestor || !authStore.isUserLoggedIn) {
      // User is Not logged in, require them to authenticate
      if (isOfferingRegD) {
        commentMeta.content = (<p>In order to leave a comment, please {get(stepInRoute, 'word')} and verify your status as an <br />accredited investor.</p>);
      } else {
        commentMeta.content = (<p>In order to leave comments, please {get(stepInRoute, 'word')}.</p>);
      }
      commentMeta.action = handleLogin => (<Link onClick={e => handleLogin(e, true)} to="/" className="ui button primary">{get(stepInRoute, 'title')}</Link>);
    } else if (activeAccounts.length === 0 && !frozenAccounts.length) {
      // TODO: need to add additional validation in, CAN post if USER !== FULL || CIP !== PASS
      // User does not have an active account, have them finish signup
      if (isOfferingRegD && !isInvestorAccreditated.status) {
        commentMeta.content = (<p>In order to leave a comment, please complete your account setup and verify your status as an accredited investor.</p>);
        commentMeta.action = () => (<Link to="/dashboard/setup" className="ui button primary">Complete Account Setup</Link>);
      } else if (signupStatus.idVerification !== 'PASS') {
        commentMeta.content = (<p>In order to leave a comment, please complete your account setup.</p>);
        commentMeta.action = () => (<Link to="/dashboard/setup" className="ui button primary">Complete Account Setup</Link>);
      } else if (signupStatus.idVerification === 'PASS') {
        commentMeta.isValid = true;
      }
    } else if (activeAccounts.length && isOfferingRegD && !isInvestorAccreditated.status) {
      commentMeta.content = (<p>In order to leave a comment, please complete verification of your status as an accredited investor.</p>);
      if (isInvestorAccreditated.requestedStatus) {
        commentMeta.content = (
          <p>In order to leave a comment, we must confirm your status as an accredited investor. If you have any questions about your
          approval status, please contact <a href="mailto: support@nextseed.com">support@nextseed.com</a>.</p>
        );
      }
      commentMeta.action = () => (!isInvestorAccreditated.requestedStatus ? (
        <Link to="/dashboard/account-settings/investment-limits/" className="ui button primary">Verify Status</Link>
      ) : true);
    } else if (!validCampaignStage || frozenAccounts.length || passedProcessingDate) {
      commentMeta.isValid = false;
    } else {
      commentMeta.isValid = true;
    }
    return commentMeta;
  }

  @computed get campaignStatus() {
    const { campaign } = this;
    const campaignStatus = {};
    const closingDate = get(campaign, 'closureSummary.processingDate') && get(campaign, 'closureSummary.processingDate') !== 'Invalid date' ? get(campaign, 'closureSummary.processingDate') : null;
    campaignStatus.campaignName = get(campaign, 'keyTerms.shorthandBusinessName') || '';
    campaignStatus.diff = DataFormatter.diffDays(closingDate || null, false, true);
    campaignStatus.diffForProcessing = DataFormatter.getDateDifferenceInHoursOrMinutes(closingDate, true, true);
    campaignStatus.countDown = (includes(['Minute Left', 'Minutes Left'], campaignStatus.diffForProcessing.label) && campaignStatus.diffForProcessing.value > 0) || campaignStatus.diffForProcessing.value <= 48 ? { valueToShow: campaignStatus.diffForProcessing.value, labelToShow: campaignStatus.diffForProcessing.label } : { valueToShow: campaignStatus.diff, labelToShow: campaignStatus.diff === 1 ? 'Day Left' : 'Days Left' };
    campaignStatus.isInProcessing = !isEmpty(campaign) && campaignStatus.diffForProcessing.value <= 0 && (!get(campaign, 'closureSummary.hardCloseDate') || get(campaign, 'closureSummary.hardCloseDate') === 'Invalid date');
    campaignStatus.collected = get(campaign, 'closureSummary.totalInvestmentAmount') || 0;
    const offeringRegulation = get(campaign, 'keyTerms.regulation');
    const minOffering = get(campaign, 'keyTerms.minOfferingAmountCF') || 0;
    const minOfferingD = get(campaign, 'keyTerms.minOfferingAmount506') && get(campaign, 'keyTerms.minOfferingAmount506') !== '0.00' ? get(campaign, 'keyTerms.minOfferingAmount506') : get(campaign, 'keyTerms.minOfferingAmount506C') ? get(campaign, 'keyTerms.minOfferingAmount506C') : '0.00';
    campaignStatus.minOffering = includes(['BD_CF_506C', 'BD_506C', 'BD_506B'], offeringRegulation) ? minOfferingD : minOffering;
    const maxOffering = get(campaign, 'keyTerms.maxOfferingAmountCF') || 0;
    const maxOfferingD = get(campaign, 'keyTerms.maxOfferingAmount506') && get(campaign, 'keyTerms.maxOfferingAmount506') !== '0.00' ? get(campaign, 'keyTerms.maxOfferingAmount506') : get(campaign, 'keyTerms.maxOfferingAmount506C') ? get(campaign, 'keyTerms.maxOfferingAmount506C') : '0.00';
    campaignStatus.maxOffering = includes(['BD_CF_506C', 'BD_506C', 'BD_506B'], offeringRegulation) ? maxOfferingD : maxOffering;
    campaignStatus.minFlagStatus = campaignStatus.collected >= campaignStatus.minOffering && campaignStatus.minOffering !== '0.00';
    campaignStatus.percentBefore = (campaignStatus.minOffering / campaignStatus.maxOffering) * 100;
    const formattedRaisedAmount = money.floatToAmount(campaignStatus.collected);
    const formattedMaxOfferingAmount = money.floatToAmount(campaignStatus.maxOffering);
    const maxReachedComparedAmount = money.cmp(formattedRaisedAmount, formattedMaxOfferingAmount);
    const formattedReachedMaxCompareAmountValue = money.floatToAmount(maxReachedComparedAmount);
    const minMaxOffering = campaignStatus.minFlagStatus
      ? campaignStatus.maxOffering : campaignStatus.minOffering;
      campaignStatus.maxFlagStatus = !isEmpty(campaign) && (!!(money.isZero(formattedReachedMaxCompareAmountValue)
      || money.isPositive(formattedReachedMaxCompareAmountValue)));
    campaignStatus.percent = (campaignStatus.collected / minMaxOffering) * 100;
    campaignStatus.address = get(campaign, 'keyTerms.city') || get(campaign, 'keyTerms.state') ? `${get(campaign, 'keyTerms.city') || ''}${get(campaign, 'keyTerms.city') && get(campaign, 'keyTerms.state') ? ',' : ''} ${get(campaign, 'keyTerms.state') || ''}` : null;
    campaignStatus.isClosed = get(campaign, 'stage') !== 'LIVE';
    campaignStatus.isCreation = get(campaign, 'stage') === 'CREATION';
    campaignStatus.earlyBird = get(campaign, 'earlyBird') || null;
    campaignStatus.bonusRewards = get(campaign, 'bonusRewards') || [];
    campaignStatus.isEarlyBirdRewards = campaignStatus.bonusRewards.filter(b => b.earlyBirdQuantity > 0).length;
    campaignStatus.isBonusReward = campaignStatus.bonusRewards && campaignStatus.bonusRewards.length;
    const elevatorPitch = (campaign && campaign.offering && campaign.offering.overview
      && campaign.offering.overview.elevatorPitch)
      || (campaign && campaign.offering && campaign.offering.overview
        && campaign.offering.overview.highlight);
    campaignStatus.hasTopThingToKnow = elevatorPitch;
    campaignStatus.dataRooms = this.dataRoomDocs.length;
    campaignStatus.campaignTemplate = get(campaign, 'template');
    campaignStatus.galleryImages = this.getGalleryImagesTemplate(campaignStatus.campaignTemplate, campaign);
    campaignStatus.gallery = get(campaign, 'media.gallery') ? get(campaign, 'media.gallery').length : 0;
    campaignStatus.keyTerms = get(campaign, 'keyTerms');
    campaignStatus.issuerStatement = campaignStatus.campaignTemplate === 2 ? get(campaign, 'misc.issuerStatement') : get(campaign, 'keyTerms.offeringDisclaimer');
    const templateNavs = [];
    if (campaignStatus.campaignTemplate === 2 && get(campaign, 'content[0]')) {
      let content = get(campaign, 'content').filter(c => this.checkValidContent(c, get(campaign, 'stage')));
      content = orderBy(content, c => c.order, ['ASC']);
      content.forEach((c, i) => templateNavs.push({ ...c, title: c.title, to: `#${camelCase(Helper.sanitize(c.title))}`, useRefLink: true, defaultActive: i === 0 }));
    }
    campaignStatus.templateNavs = templateNavs;
    campaignStatus.companyDescription = get(campaign, 'offering.about.theCompany');
    campaignStatus.businessModel = get(campaign, 'offering.about.businessModel');
    campaignStatus.localAnalysis = get(campaign, 'offering.about.locationAnalysis');
    campaignStatus.history = get(campaign, 'offering.about.history');
    campaignStatus.team = get(campaign, 'leadership');
    campaignStatus.useOfProcceds = get(campaign, 'legal.general.useOfProceeds.offeringExpenseAmountDescription');
    campaignStatus.revenueSharingSummary = get(campaign, 'keyTerms.revShareSummary');
    campaignStatus.updates = get(campaign, 'updates') && get(campaign, 'updates').length;
    campaignStatus.investmentHighlights = true;
    campaignStatus.investmentSummary = get(campaign, 'investmentSummary');
    campaignStatus.isRevenueShare = this.offerStructure === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.REVENUE_SHARING_NOTE && campaignStatus.revenueSharingSummary;
    campaignStatus.isTermNote = this.offerStructure === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.TERM_NOTE;
    campaignStatus.isFund = this.offerStructure === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.FUNDS;
    campaignStatus.isSafe = this.offerStructure === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.SAFE;
    campaignStatus.isConvertibleNotes = this.offerStructure === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.CONVERTIBLE_NOTES;
    campaignStatus.isEquity = this.offerStructure === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.EQUITY;
    campaignStatus.isRealEstate = ((this.offerStructure === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.EQUITY && get(campaign, 'keyTerms.equityClass') === 'LLC_MEMBERSHIP_UNITS'));
    campaignStatus.isPreferredEquity = ((this.offerStructure === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.EQUITY && ((get(campaign, 'keyTerms.equityClass') === 'PREFERRED' || (get(campaign, 'keyTerms.equityClass') === 'PREFERRED_EQUITY')) || (this.inInvestmentFlow && ['CLASS_A_SHARES', 'CLASS_B_SHARES', 'PARALLEL_CLASS_SHARES'].includes(get(campaign, 'keyTerms.equityClass'))))));
    campaignStatus.doneComputing = (get(this.details, 'data.getOfferingDetailsBySlug') && !isEmpty(this.details.data.getOfferingDetailsBySlug.keyTerms)) || false;
    campaignStatus.isAgreementTemplate = !!(get(campaign, 'investNow.template') && get(campaign, 'investNow.template') === 2);
    campaignStatus.investNowToc = get(campaign, 'investNow.page');
    campaignStatus.investNowConig = !!(get(campaign, 'investNow.config'));
    campaignStatus.investmentType = get(campaign, 'investNow.config.investmentType');
    campaignStatus.showBonusRewards = get(campaign, 'investNow.config.showBonusRewards');
    campaignStatus.showExpectedReturn = get(campaign, 'investNow.config.showExpectedReturn');
    campaignStatus.expectedReturnCalc = get(campaign, 'investNow.config.expectedReturnCalc');
    campaignStatus.hideConfirmationHeader = get(campaign, 'investNow.config.hideConfirmationHeader');
    campaignStatus.hideConfirmationReferral = get(campaign, 'investNow.config.hideConfirmationReferral');
    campaignStatus.confirmationMessage = get(campaign, 'investNow.config.confirmationMessage');
    return campaignStatus;
  }

  getGalleryImagesTemplate = (campaignTemplate, campaign) => {
    let gallery = [];
    if (campaignTemplate === 2) {
      const galleryObj = get(campaign, 'gallery') || [];
      gallery = orderBy(galleryObj.filter(i => i.isVisible), ['order'], ['asc']);
    } else {
      gallery = get(campaign, 'media.gallery') || [];
    }
    return gallery;
  }

  @computed get getOfferingId() {
    return (this.campaign && this.campaign.id);
  }

  @computed get getOfferingSlug() {
    return (this.campaign && this.campaign.offeringSlug);
  }

  @action
  getOfferingDetailsForSignupStep = id => new Promise((resolve, reject) => {
    const gqlClient = authStore.isUserLoggedIn ? client : clientPublic;
    this.details = graphql({
      client: gqlClient,
      fetchPolicy: 'cache-first',
      query: getOfferingForInvestorSignupBySlug,
      variables: { id },
      onFetch: (data) => {
        if (data && data.getOfferingDetailsBySlug && !this.details.loading) {
          resolve(data.getOfferingDetailsBySlug);
        }
      },
      onError: (err) => {
        window.logger(err);
        reject(err);
      },
    });
  });

  @action
  isEarlyBirdExist(accountType, isAdmin = false) {
    const offeringId = this.getOfferingId;
    userDetailsStore.setFieldValue('currentActiveAccount', accountType);
    const accountDetails = userDetailsStore.currentActiveAccountDetailsOfSelectedUsers;
    const account = isAdmin ? accountDetails : userDetailsStore.currentActiveAccountDetails;
    const accountId = get(account, 'details.accountId') || null;
    if (offeringId && accountId) {
      this.earlyBirdCheck = graphql({
        client,
        query: checkIfEarlyBirdExist,
        variables: { offeringId, accountId },
      });
    }
  }

  @action
  showMixMaxOfferingAmountCalc = () => {
    const { minFlagStatus, isFund, minOffering, maxOffering } = this.campaignStatus;
    const toggleMetaArr = get(this.campaign, 'header.toggleMeta') || [];
    const showMinMaxTarget = {
      showMin: false,
      showMax: false,
      minValue: Helper.CurrencyFormat(minOffering, 0),
      maxValue: Helper.CurrencyFormat(maxOffering, 0),
      minMessage: 'If the minimum goal is not met by the end of the offering period, any funds you invest will be automatically returned to your NextSeed account.',
      maxMessage: 'The offering will remain open until the issuer raises the maximum goal or the offering period ends. As long as the raise exceeds the minimum goal, the issuer will receive the funds.',
    };
    if (isFund) {
      if (!toggleMetaArr.includes('MINIMUM_TARGET')) {
        showMinMaxTarget.showMin = true;
      }
      if (!toggleMetaArr.includes('MAXIMUM_TARGET')) {
        showMinMaxTarget.showMax = true;
      }
    } else {
      if (minFlagStatus && !toggleMetaArr.includes('MAXIMUM_TARGET')) {
        showMinMaxTarget.showMax = true;
      }
      if (!minFlagStatus && !toggleMetaArr.includes('MINIMUM_TARGET')) {
        showMinMaxTarget.showMin = true;
      }
    }
    return showMinMaxTarget;
  }

  @computed
  get earlyBirdRewards() {
    const currentCampagin = this.campaign;
    const rewards = get(currentCampagin, 'bonusRewards') || [];
    return filter(rewards, br => br.earlyBirdQuantity);
  }

  @computed get loading() {
    return this.allData.loading || this.details.loading;
  }

  @computed get completedLoading() {
    return this.completedOfferings.loading;
  }

  @action
  setInitialStateForReadMoreAndReadLess(updatesData) {
    if (updatesData.length) {
      this.selectedReadMore = updatesData.map(() => true);
      this.selectedReadLess = updatesData.map(() => true);
    }
  }

  @computed get curretnStatusForReadMore() {
    return this.selectedReadMore;
  }

  @computed get curretnStatusForReadLess() {
    return this.selectedReadLess;
  }

  @action
  handleReadMoreReadLess(index) {
    const newReadMoreStatus = [...this.selectedReadMore];
    newReadMoreStatus[index] = !this.selectedReadMore[index];
    this.selectedReadMore = newReadMoreStatus;

    const newReadLessStatus = [...this.selectedReadLess];
    newReadLessStatus[index] = !this.selectedReadLess[index];
    this.selectedReadLess = newReadLessStatus;
  }

  @computed get minInvestAmt() {
    return this.campaign && this.campaign.keyTerms ? this.campaign.keyTerms.minInvestAmt : null;
  }

  @computed get dataRoomDocs() {
    return this.campaign && this.campaign.legal && this.campaign.legal.dataroom
      && this.campaign.legal.dataroom.documents
      ? this.campaign.legal.dataroom.documents : [];
  }

  @action
  getAllBoxLinks = (accountType) => {
    this.docsWithBoxLink = [];
    this.dataRoomDocs.forEach(async (ele) => {
      const tempEle = { ...ele };
      if (get(ele, 'upload.fileHandle.boxFileId')) {
        tempEle.BoxUrl = await this.getBoxLink(get(ele, 'upload.fileHandle.boxFileId'), accountType);
        if (!filter(this.docsWithBoxLink, e => get(e, 'upload.fileId') === get(ele, 'upload.fileId')).length) {
          this.updateDocs(tempEle);
        }
      }
    });
  }

  @action
  updateDocs = ele => this.docsWithBoxLink.push(ele);

  getIndexValue = vale => this.campaign.legal.dataroom.documents
    && this.campaign.legal.dataroom.documents
      .findIndex(x => x.upload.fileId === vale);

  @computed get sortedDocswithBoxLink() {
    return this.docsWithBoxLink.sort((a, b) => (this.getIndexValue(a.upload.fileId) > this.getIndexValue(b.upload.fileId) ? 1 : -1));
  }

  @computed get commentsMainThreadCount() {
    const comments = get(this.campaign, 'comments') || [];
    const issuerId = this.campaign && this.campaign.issuerId;
    const filtered = comments.filter(c => ((c.createdUserInfo && c.createdUserInfo.id === issuerId
      && c.approved)
      || (c.createdUserInfo && c.createdUserInfo.id !== issuerId)) && c.scope === 'PUBLIC');
    return filtered;
  }

  getBoxLink = (fileId, accountType) => new Promise((resolve) => {
    this.setFieldValue('docLoading', true);
    clientPublic.mutate({
      mutation: getBoxEmbedLink,
      variables: { fileId, accountType },
    }).then((res) => {
      resolve(res.data.getBoxEmbedLink);
      this.setFieldValue('docLoading', false);
    }).catch(() => {
      this.setFieldValue('isFetchedError', true);
      this.setFieldValue('docLoading', false);
      Helper.toast('Something went wrong. Please try again in some time.', 'error');
    });
  });

  // @computed get navCountData() {
  //   const res = { updates: 0, comments: 0 };
  //   let sum = 0;
  //   if (this.campaign) {
  //     const { updates, comments } = this.campaign;
  //     res.updates = updates && updates.length ? updates.length : 0;
  //     if (comments) {
  //       comments.map((c) => {
  //         if (c.scope === 'PUBLIC'
  //           && ((['admin', 'investor'].includes(get(c, 'createdUserInfo.roles[0].name')))
  //             || (get(c, 'createdUserInfo.roles[0].name') === 'issuer' && c.approved))) {
  //           const cnt = reduce(get(c, 'threadComments'), (tcSum, tc) => (tc.scope === 'PUBLIC' && ((['admin', 'investor'].includes(get(tc, 'createdUserInfo.roles[0].name'))) || (get(tc, 'createdUserInfo.roles[0].name') === 'issuer' && tc.approved)) ? (tcSum + 1) : tcSum), 0);
  //           sum = sum + 1 + (cnt || 0);
  //         }
  //         return null;
  //       });
  //     }
  //   }
  //   res.comments = sum;
  //   return res;
  // }

  @action
  navCountData = () => {
    let sum = 0;
    if (this.campaign) {
      const { updates, comments } = this.campaign;
      const updatesCnt = updates && updates.length ? updates.length : 0;
      this.setFieldValue('postNavCount', updatesCnt, 'updates');
      if (comments) {
        comments.map((c) => {
          if (c.scope === 'PUBLIC'
            && ((['admin', 'investor'].includes(get(c, 'createdUserInfo.roles[0].name')))
              || (get(c, 'createdUserInfo.roles[0].name') === 'issuer' && c.approved))) {
            const cnt = reduce(get(c, 'threadComments'), (tcSum, tc) => (tc.scope === 'PUBLIC' && ((['admin', 'investor'].includes(get(tc, 'createdUserInfo.roles[0].name'))) || (get(tc, 'createdUserInfo.roles[0].name') === 'issuer' && tc.approved)) ? (tcSum + 1) : tcSum), 0);
            sum = sum + 1 + (cnt || 0);
          }
          return null;
        });
      }
    }
    const postedCommentCount = get(this.campaign, 'comments') || [];
    this.setFieldValue('postNavCount', sum, 'comments');
    this.setFieldValue('postedCommentCount', postedCommentCount.length);
  }

  @computed get offerStructure() {
    return get(this.campaign, 'keyTerms.securities') || '';
  }

  @action
  calculateTotalPaymentData = (amt = 0) => {
    const ranges = [100, 500, 1000, 5000, 10000, 25000, 50000];
    this.principalAmt = ranges[amt];
    const data = {
      method: 'mortgage',
      apr: parseFloat(get(this.campaign, 'keyTerms.interestRate')) || 0,
      balance: this.principalAmt || 0,
      loanTerm: parseFloat(get(this.campaign, 'keyTerms.maturity')) || 0,
    };
    const { totalPayment, schedule } = Calculator.calculate(data);
    this.totalPayment = money.floatToAmount(totalPayment || '', 2);
    const payChart = [];
    let totalPaid = 0;
    if (schedule && Array.isArray(schedule)) {
      schedule.forEach((item, index) => {
        totalPaid = totalPaid + item.interest + item.principal;
        payChart.push({
          month: index + 1,
          'Projected total payment': parseFloat((totalPaid).toFixed(2)),
        });
      });
    }
    this.totalPaymentChart = payChart;
  }

  @action
  getPercentFunded = (offeringDetails) => {
    let percentageCopy = null;
    const offeringKeyTermDetails = get(offeringDetails, 'keyTerms');
    const minimumOfferingAmountCF = get(offeringKeyTermDetails, 'minOfferingAmountCF') || '0.00';
    const minimumOfferingAmountRegD = get(offeringKeyTermDetails, 'minOfferingAmount506') && get(offeringKeyTermDetails, 'minOfferingAmount506') !== '0.00' ? get(offeringKeyTermDetails, 'minOfferingAmount506') : get(offeringKeyTermDetails, 'minOfferingAmount506C') ? get(offeringKeyTermDetails, 'minOfferingAmount506C') : '0.00';
    const maxOfferingAmountCF = get(offeringKeyTermDetails, 'maxOfferingAmountCF') || '0.00';
    const maxOfferingAmountRegD = get(offeringKeyTermDetails, 'maxOfferingAmount506') && get(offeringKeyTermDetails, 'maxOfferingAmount506') !== '0.00' ? get(offeringKeyTermDetails, 'maxOfferingAmount506') : get(offeringKeyTermDetails, 'maxOfferingAmount506C') ? get(offeringKeyTermDetails, 'maxOfferingAmount506C') : '0.00';
    const regulation = get(offeringKeyTermDetails, 'regulation');
    const securities = get(offeringKeyTermDetails, 'securities');
    const isRealEstate = (securities === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.EQUITY && get(offeringKeyTermDetails, 'equityClass') === 'LLC_MEMBERSHIP_UNITS');
    const minimumOfferingAmount = includes(['BD_CF_506C', 'BD_506C', 'BD_506B'], regulation) ? minimumOfferingAmountRegD : minimumOfferingAmountCF;
    const maxOfferingAmount = includes(['BD_CF_506C', 'BD_506C', 'BD_506B'], regulation) ? maxOfferingAmountRegD : maxOfferingAmountCF;
    const raisedAmount = get(offeringDetails, 'closureSummary.totalInvestmentAmount') ? money.floatToAmount(get(offeringDetails, 'closureSummary.totalInvestmentAmount')) : '0.00';
    const divResult = money.div(raisedAmount, minimumOfferingAmount);
    const percent = money.mul(divResult, '100.00');
    const percentageCompairResult = money.cmp(percent, '50.00').toString();
    const amountCompairResult = money.cmp(raisedAmount, maxOfferingAmount).toString();
    if (money.isNegative(amountCompairResult)
      && !money.isZero(percentageCompairResult) && !isRealEstate) {
      percentageCopy = `${Helper.CommaFormat(Math.round(percent))}% of minimum goal`;
    } else if (money.isZero(amountCompairResult) || !money.isNegative(amountCompairResult)) {
      percentageCopy = 'Reached Max';
    }
    return percentageCopy;
  }

  generateBanner = (offeringDetailsList, addObjectProps = false, isFromAdmin = false) => {
    let closingOfferingsArr = [];
    let newOfferingsArr = [];
    let otherOfferingsArr = [];
    let reachedMaxOfferingsArr = [];
    let processingOfferingsArr = [];
    offeringDetailsList.map((offeringDetails) => {
      const offeringKeyTermDetails = get(offeringDetails, 'keyTerms');
      const minimumOfferingAmountCF = get(offeringKeyTermDetails, 'minOfferingAmountCF') || '0.00';
      const minimumOfferingAmountRegD = get(offeringKeyTermDetails, 'minOfferingAmount506') && get(offeringKeyTermDetails, 'minOfferingAmount506') !== '0.00' ? get(offeringKeyTermDetails, 'minOfferingAmount506') : get(offeringKeyTermDetails, 'minOfferingAmount506C') ? get(offeringKeyTermDetails, 'minOfferingAmount506C') : '0.00';
      const maxOfferingAmountCF = get(offeringKeyTermDetails, 'maxOfferingAmountCF') || '0.00';
      const maxOfferingAmountRegD = get(offeringKeyTermDetails, 'maxOfferingAmount506') && get(offeringKeyTermDetails, 'maxOfferingAmount506') !== '0.00' ? get(offeringKeyTermDetails, 'maxOfferingAmount506') : get(offeringKeyTermDetails, 'maxOfferingAmount506C') ? get(offeringKeyTermDetails, 'maxOfferingAmount506C') : '0.00';
      const regulation = get(offeringKeyTermDetails, 'regulation');
      const securities = get(offeringKeyTermDetails, 'securities');
      const isRealEstate = (securities === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.EQUITY && get(offeringKeyTermDetails, 'equityClass') === 'LLC_MEMBERSHIP_UNITS');
      const minimumOfferingAmount = includes(['BD_CF_506C', 'BD_506C', 'BD_506B'], regulation) ? minimumOfferingAmountRegD : minimumOfferingAmountCF;
      const launchDate = get(offeringDetails, 'closureSummary.launchDate') && get(offeringDetails, 'closureSummary.launchDate') !== 'Invalid date' ? get(offeringDetails, 'closureSummary.launchDate') : null;
      const closingDate = get(offeringDetails, 'closureSummary.processingDate') && get(offeringDetails, 'closureSummary.processingDate') !== 'Invalid date' ? get(offeringDetails, 'closureSummary.processingDate') : null;
      const maxOfferingAmount = includes(['BD_CF_506C', 'BD_506C', 'BD_506B'], regulation) ? maxOfferingAmountRegD : maxOfferingAmountCF;
      const raisedAmount = get(offeringDetails, 'closureSummary.totalInvestmentAmount') ? money.floatToAmount(get(offeringDetails, 'closureSummary.totalInvestmentAmount')) : '0.00';
      const divResult = money.div(raisedAmount, minimumOfferingAmount);
      const percent = money.mul(divResult, '100.00');
      const offeringCustomOrder = get(offeringDetails, 'order');
      const resultObject = addObjectProps ? { ...offeringDetails } : {};
      const customAddinggDaysDateObj = {
        number: 7,
        format: 'days',
      };
      const launchDaysToRemainsForNewLable = DataFormatter.diffDaysForLauch(
        launchDate || null,
        false, true, true, customAddinggDaysDateObj,
      );
      /* const customAddingHoursDateObject = {
        number: 48,
        format: 'Hours',
      };
      const launchDaysToRemains = DataFormatter.diffDaysForLauch(
        launchDate || null,
        false, true, true, customAddingHoursDateObject,
      ); */
      const closeDaysToRemains = DataFormatter.diffDays(closingDate || null, false, true);
      const closeDaysToRemainsInHours = DataFormatter.getDateDifferenceInHoursOrMinutes(closingDate, true, true);
      const isInProcessing = closeDaysToRemainsInHours.value <= 0 && (!get(offeringDetails, 'closureSummary.hardCloseDate') || get(offeringDetails, 'closureSummary.hardCloseDate') === 'Invalid date');
      const percentageCompairResult = money.cmp(percent, '50.00').toString();
      const amountCompairResult = money.cmp(raisedAmount, maxOfferingAmount).toString();
      let isReachedMax = false;
      if (money.isZero(amountCompairResult) || !money.isNegative(amountCompairResult)) {
        isReachedMax = true;
      }
      if (launchDate && (launchDaysToRemainsForNewLable < closeDaysToRemains
        || closeDaysToRemains === null)
        && launchDaysToRemainsForNewLable >= 0 && launchDaysToRemainsForNewLable <= 7) {
        resultObject.isBannerShow = true;
        resultObject.datesBanner = 'NEW';
        resultObject.amountsBanner = this.generateLabelBannerSecond(amountCompairResult, percentageCompairResult, percent, isRealEstate);
        if (isRealEstate) {
          resultObject.realEstateBanner = 'Real Estate';
        }
        resultObject.launchDate = moment(launchDate).unix() || null;
        resultObject.processingDate = moment(closingDate).unix() || null;
        resultObject.category = 'newOffering';
        return newOfferingsArr.push(resultObject);
      } if (closingDate && closeDaysToRemains >= 0 && closeDaysToRemains <= 7 && !isInProcessing) {
        const labelBannerFirst = ((includes(['Minute Left', 'Minutes Left'], closeDaysToRemainsInHours.label) && closeDaysToRemainsInHours.value > 0) || closeDaysToRemainsInHours.value <= 48) ? `${closeDaysToRemainsInHours.value} ${closeDaysToRemainsInHours.label}` : closeDaysToRemains === 1 ? `${closeDaysToRemains} Day Left` : `${closeDaysToRemains} Days Left`;
        resultObject.isBannerShow = !!labelBannerFirst;
        resultObject.datesBanner = labelBannerFirst;
        resultObject.amountsBanner = this.generateLabelBannerSecond(amountCompairResult, percentageCompairResult, percent, isRealEstate);
        if (isRealEstate) {
          resultObject.realEstateBanner = 'Real Estate';
        }
        resultObject.launchDate = moment(launchDate).unix() || null;
        resultObject.processingDate = moment(closingDate).unix() || null;
        resultObject.category = 'closingSoon';
        if (!isReachedMax) {
          return closingOfferingsArr.push(resultObject);
        }
      } if (isInProcessing) {
        resultObject.isBannerShow = true;
        resultObject.datesBanner = 'Processing';
        if (isRealEstate) {
          resultObject.realEstateBanner = 'Real Estate';
        }
        resultObject.launchDate = moment(launchDate).unix() || null;
        resultObject.processingDate = moment(closingDate).unix() || null;
        resultObject.category = 'processing';
        return processingOfferingsArr.push(resultObject);
      }
      // if (launchDate && (launchDaysToRemainsForNewLable < closeDaysToRemains
      //   || closeDaysToRemains === null)
      //   && launchDaysToRemainsForNewLable >= 0 && launchDaysToRemainsForNewLable <= 7) {
      //   resultObject.datesBanner = 'NEW';
      // }
      resultObject.amountsBanner = this.generateLabelBannerSecond(amountCompairResult, percentageCompairResult, percent, isRealEstate);
      if (isRealEstate) {
        resultObject.realEstateBanner = 'Real Estate';
      }
      resultObject.isBannerShow = !!(resultObject.datesBanner || resultObject.amountsBanner);
      resultObject.launchDate = moment(launchDate).unix() || null;
      resultObject.processingDate = moment(closingDate).unix() || null;
      if (money.isZero(amountCompairResult) || !money.isNegative(amountCompairResult)) {
        resultObject.category = 'reachedMax';
        return reachedMaxOfferingsArr.push(resultObject);
      }
      resultObject.order = offeringCustomOrder;
      resultObject.category = 'other';
      return otherOfferingsArr.push(resultObject);
    });
    newOfferingsArr = orderBy(newOfferingsArr, ['launchDate'], ['desc']);
    closingOfferingsArr = orderBy(closingOfferingsArr, ['processingDate'], ['asc']);
    processingOfferingsArr = orderBy(processingOfferingsArr, ['processingDate'], ['desc']);
    otherOfferingsArr = orderBy(otherOfferingsArr, ['order'], ['asc']);
    reachedMaxOfferingsArr = orderBy(reachedMaxOfferingsArr, ['processingDate'], ['asc']);
    // const sortedResultObject = [];
    if (isFromAdmin) {
      const sortedResultObject = [
        { category: 'closingSoonAndNew', title: 'Closing Soon and New', offerings: [...closingOfferingsArr, ...newOfferingsArr] },
        { category: 'other', title: 'Current Offerings', offerings: [...otherOfferingsArr] },
        { category: 'reachedMaxAndProcessing', title: 'Reached Max and Processing', offerings: [...reachedMaxOfferingsArr, ...processingOfferingsArr] },
      ];
      return sortedResultObject;
    }
    const sortedResultObject = [
      ...closingOfferingsArr,
      ...newOfferingsArr,
      ...otherOfferingsArr,
      ...reachedMaxOfferingsArr,
      ...processingOfferingsArr,
    ];
    return sortedResultObject;
  }

  generateLabelBannerSecond = (amountCompairResult, percentageCompairResult, percent, isRealEstate) => {
    let labelBannerSecond = null;
    if (money.isNegative(amountCompairResult)
      && !money.isZero(percentageCompairResult) && !money.isNegative(percentageCompairResult) && !isRealEstate) {
      labelBannerSecond = `${Math.round(percent)}% Funded`;
    } else if (money.isZero(amountCompairResult) || !money.isNegative(amountCompairResult)) {
      labelBannerSecond = 'Reached Max';
    }
    return labelBannerSecond;
  }

  @action
  modifySubNavs = (navList, newLayout = false) => {
    const newNavList = [];
    const offeringStage = get(this.campaign, 'stage');
    navList.forEach((item) => {
      const tempItem = item;
      if (!newLayout) {
        let temNavList = item.subNavigations;
        if (has(item, 'subNavigations') && item.title === 'Investment Details') {
          const existanceResult = filter(temNavList, o => o.title === 'Revenue Sharing Summary' || o.title === 'Total Payment Calculator');
          if (this.offerStructure === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.REVENUE_SHARING_NOTE) {
            if (existanceResult.length) {
              remove(temNavList, n => n.title === 'Revenue Sharing Summary' || n.title === 'Total Payment Calculator');
            }
            temNavList.push({
              title: 'Revenue Sharing Summary', to: '#revenue-sharing-summary', useRefLink: true, key: 'revenueSharingSummary',
            });
          } else if (this.offerStructure === CAMPAIGN_KEYTERMS_SECURITIES_ENUM.TERM_NOTE) {
            if (existanceResult.length) {
              remove(temNavList, n => n.title === 'Revenue Sharing Summary' || n.title === 'Total Payment Calculator');
            }
            // temNavList.push({
            //   title: 'Total Payment Calculator', to: '#total-payment-calculator', useRefLink: true,
            // });
          } else if (existanceResult.length) {
            remove(temNavList, n => n.title === 'Revenue Sharing Summary' || n.title === 'Total Payment Calculator');
          }
          tempItem.subNavigations = uniqWith(temNavList, isEqual);
        }
        if (tempItem.title === 'Summary' || tempItem.title === 'About the Company' || tempItem.title === 'Investment Details') {
          const arr = temNavList;
          if (arr && Array.isArray(arr)) {
            arr.forEach((i) => {
              if (i.key && !this.campaignStatus[i.key]) {
                temNavList = temNavList.filter(n => n.title !== i.title);
              }
            });
          }
        }
        if (temNavList && Array.isArray(temNavList)) {
          temNavList.forEach((i) => {
            if (i.key && !this.campaignStatus[i.key]) {
              temNavList = temNavList.filter(n => n.title !== i.title);
            }
          });
        }
        tempItem.subNavigations = temNavList;
        if (tempItem.to === 'data-room') {
          if (['CREATION', 'LIVE', 'LOCK', 'PROCESSING'].includes(offeringStage)) {
            newNavList.push(tempItem);
          }
        } else if (!temNavList || (temNavList && temNavList.length)) {
          newNavList.push(tempItem);
        }
      } else if (tempItem && tempItem.key && this.campaignStatus[tempItem.key]) {
        newNavList.push(tempItem);
      } else if (tempItem && !tempItem.key) {
        newNavList.push(tempItem);
      }
    });
    this.setFieldValue('campaignNavData', newNavList);
    return newNavList;
  }
}

export default new CampaignStore();
